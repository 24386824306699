import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { createDocumentsMapList } from '../../lib/blocks';
import { getComponentFromName } from '@folklore/utils';

import Blocks from '../blocks/Blocks';
import * as iconComponents from '../icons/maps/index';
import LocationAddresss from '../maps/LocationAddress';
import MenuSection from '../typography/MenuSection';
import SidebarSubtitle from '../typography/SidebarSubtitle';
import SidebarTitle from '../typography/SidebarTitle';

import styles from '../../styles/panels/location-panel.module.css';

const propTypes = {
    location: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        slug: PropTypes.string,
        label: PropTypes.string,
    }).isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function LocationPanel({ location, className }) {
    const { name: title = null, type = null, types = null, id: locationId = null } = location || {};

    const blocks = useMemo(
        () => [
            createDocumentsMapList(
                { location: locationId },
                {
                    title: (
                        <MenuSection className={styles.sectionTitle}>
                            <FormattedMessage
                                defaultMessage="Contenus associés"
                                description="List title"
                            />
                        </MenuSection>
                    ),
                    itemsClassName: styles.items,
                },
            ),
        ],
        [locationId],
    );

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.header}>
                {title !== null ? (
                    <SidebarTitle className={styles.title}>{title}</SidebarTitle>
                ) : null}

                {types !== null && types.length > 0
                    ? (types || []).map(
                          ({
                              icon: typeIcon = null,
                              slug: typeSlug = null,
                              label: typeLabel = null,
                          }) => {
                              const IconComponent = getComponentFromName(
                                  iconComponents,
                                  typeIcon || typeSlug,
                                  'autres-sports',
                              );
                              return (
                                  <div
                                      key={`location-type-${typeSlug}`}
                                      className={styles.typeLabel}
                                  >
                                      <IconComponent className={styles.icon} />
                                      <SidebarSubtitle>{typeLabel}</SidebarSubtitle>
                                  </div>
                              );
                          },
                      )
                    : null}

                <LocationAddresss location={location} className={styles.address} />
            </div>
            {type !== 'toyota' ? (
                <Blocks blocks={blocks} blockClassName={styles.list} />
            ) : // <DocumentsList
            //     header={
            //         <SidebarListTitle>
            //             <FormattedMessage defaultMessage="Contenus associés" />
            //         </SidebarListTitle>
            //     }
            //     query={{ location: locationId }}
            //     presentation="grid"
            //     columns={1}
            //     cardType="horizontal"
            //     cardTheme="box-translucent-dark"
            //     cardRatio="vertical"
            //     cardWithout={['duration', 'time']}
            //     cardWith="locations"
            //     className={styles.list}
            // />
            null}
        </div>
    );
}

LocationPanel.propTypes = propTypes;
LocationPanel.defaultProps = defaultProps;

export default LocationPanel;
