/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

// import * as AppPropTypes from '../../lib/PropTypes';
import PillButton from '../buttons/PillButton';
import ExternalLinkIcon from '../icons/ExternalLinkIcon';
import SidebarListTitle from '../typography/SidebarListTitle';

import styles from '../../styles/maps/location-address.module.css';

const propTypes = {
    location: PropTypes.shape({
        name: PropTypes.string,
        address: PropTypes.string,
        city: PropTypes.string,
    }).isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function LocationAddress({ location, className }) {
    const {
        name = null,
        address: streetAddress = null,
        streetNumber = null,
        streetName = null,
        city = null,
        postalCode = null,
        region = null,
    } = location || {};

    const address = (
        streetNumber !== null || streetName !== null
            ? [streetNumber, streetName, city, region, postalCode]
            : [streetAddress, city, region, postalCode]
    )
        .filter((it) => it !== null && it.length > 0)
        .join(', ');

    const gmapsQuery = useMemo(
        () =>
            queryString.stringify({
                query: [name, address].filter((it) => it !== null && it.length > 0).join(', '),
            }),
        [name, address],
    );

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <SidebarListTitle className={styles.title}>{address}</SidebarListTitle>
            <PillButton
                href={`https://www.google.com/maps/search/?api=1&${gmapsQuery}`}
                external
                target="_blank"
                className={styles.button}
            >
                <FormattedMessage
                    defaultMessage="Ouvrir dans Google Maps"
                    description="Button label"
                />
                <ExternalLinkIcon className={styles.icon} />
            </PillButton>
        </div>
    );
}

LocationAddress.propTypes = propTypes;
LocationAddress.defaultProps = defaultProps;

export default LocationAddress;
