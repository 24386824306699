import { useSpring } from '@react-spring/web';
import { useCallback, useState } from 'react';

export default function useSidebarSpring({
    opened = false,
    sidebarVisibleHeight = 0,
    sidebarWidth = 0,
    mobile = true,
} = {}) {
    const [sidebarClosed, setSidebarClosed] = useState(!opened);
    const onSidebarSpringResolve = useCallback(() => {
        setSidebarClosed(!opened);
    }, [opened, setSidebarClosed]);
    const { offset, ...contentStyle } = useSpring(
        mobile
            ? {
                  offset: opened ? sidebarVisibleHeight : 0,
              }
            : {
                  offset: opened ? sidebarWidth : 0,
              },
    );
    const { offset: sidebarOffset } = useSpring(
        mobile
            ? {
                  offset: opened ? sidebarVisibleHeight : 0,
              }
            : {
                  offset: opened ? 0 : -sidebarWidth,

                  onResolve: onSidebarSpringResolve,
              },
    );

    return {
        contentStyle: {
            ...contentStyle,
            height: mobile ? offset.to((val) => `calc(100vh - ${val}px)`) : offset.to(() => '100%'),
            width: mobile ? offset.to(() => '100%') : offset.to((val) => `calc(100% - ${val}px)`),
        },
        sidebarStyle: {
            x: mobile ? sidebarOffset.to(() => 0) : sidebarOffset,
            marginTop: mobile ? sidebarOffset.to((val) => `calc(100svh - ${val}px)`) : 0,
        },
        sidebarClosed,
        visible: !sidebarClosed || opened,
    };
}
