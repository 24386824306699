/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import MapControlButton from '../buttons/MapControlButton';
import PortalControl from './PortalControl';

import styles from '../../styles/maps/navigation-control.module.css';

const propTypes = {
    zoomIn: PropTypes.func,
    zoomOut: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    zoomIn: null,
    zoomOut: null,
    className: null,
};

function NavigationControl({ zoomIn, zoomOut, className }) {
    return (
        <PortalControl
            position="top-right"
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <MapControlButton icon="zoom-in" onClick={zoomIn} className={styles.button} />
            <MapControlButton icon="zoom-out" onClick={zoomOut} className={styles.button} />
        </PortalControl>
    );
}

NavigationControl.propTypes = propTypes;
NavigationControl.defaultProps = defaultProps;

export default NavigationControl;
