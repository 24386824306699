/* eslint-disable react/jsx-props-no-spreading */
import { useUrlGenerator } from '@folklore/routes';
import { animated } from '@react-spring/web';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation as useWouterLocation, useParams } from 'wouter';

import useDocument from '../../hooks/useDocument';
import useElementSize from '../../hooks/useElementSize';
import useFlyTo from '../../hooks/useFlyTo';
import useLocation from '../../hooks/useLocation';
import useLocationType from '../../hooks/useLocationType';
import useLocations from '../../hooks/useLocations';
import useMapViewport from '../../hooks/useMapViewport';
import useRegion from '../../hooks/useRegion';
import useScrollEnter from '../../hooks/useScrollEnter';
import useWindowSize from '../../hooks/useWindowSize';
import { getPointsFromLocations } from '../../lib/maps';

import useMapSidebarSpring from '../../animations/useMapSidebarSpring';
import { useGlobalBrand } from '../../contexts/LayoutContext';
import siteBreakpoints from '../../data/breakpoints';
import Clusters from '../maps/Clusters';
import Map from '../maps/Map';
import MapSidebar from '../maps/MapSidebar';

import styles from '../../styles/pages/map-page.module.css';

import toyotaDealers from '../../data/toyota.json';

const propTypes = {
    current: PropTypes.bool,
    maxZoom: PropTypes.number,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    onEnter: PropTypes.func,
    onLeave: PropTypes.func,
};

const defaultProps = {
    current: false,
    maxZoom: 14,
    className: null,
    contentClassName: null,
    onEnter: null,
    onLeave: null,
};

function MapPage({ current, maxZoom, className, contentClassName, onEnter, onLeave }) {
    const globalBrand = useGlobalBrand();
    const { handle: brandHandle = null } = globalBrand;

    const {
        region: regionSlug = null,
        location: locationSlug = null,
        locationType: locationTypeSlug = null,
        document: documentSlug = null,
    } = useParams();

    const [, setLocation] = useWouterLocation();
    const url = useUrlGenerator();

    // PAGE NAVIGATION
    useScrollEnter({
        disabled: !current,
        onEnter,
        onLeave,
    });

    // SIDEBAR
    const [sidebarOpened, setSidebarOpened] = useState(true);

    const toggleSidebar = useCallback(() => {
        setSidebarOpened(!sidebarOpened);
    }, [sidebarOpened, setSidebarOpened]);

    const { width: windowWidth = 0 } = useWindowSize();
    const { ref: sidebarRef, width: sidebarWidth = 0 } = useElementSize();
    const isMobile = windowWidth < siteBreakpoints.small;

    const { contentStyle, sidebarStyle } = useMapSidebarSpring({
        opened: sidebarOpened,
        sidebarWidth,
        sidebarVisibleHeight: 200,
        mobile: isMobile,
    });

    // LOCATION POINTS
    const { items: locations } = useLocations({
        country: 'CA',
        // region: 'Québec',
    });

    const articlesPoints = useMemo(
        () =>
            getPointsFromLocations(locations).map((point) => ({
                ...point,
                properties: {
                    ...point.properties,
                    active: point.properties.location.slug === locationSlug,
                },
            })),
        [locations, locationSlug],
    );

    const toyotaPoints = useMemo(() => getPointsFromLocations(toyotaDealers), [toyotaDealers]);

    const boundsPoints = useMemo(
        () => [...articlesPoints, ...toyotaPoints],
        [articlesPoints, toyotaPoints],
    );

    // MAP
    const mapRef = useRef(null);

    const {
        ref: mapSizeRef,
        width: mapWidth,
        height: mapHeight,
        viewport,
        setViewport,
        updateBounds,
        bounds,
    } = useMapViewport({
        boundsPoints,
        maxZoom,
    });

    useEffect(() => {
        updateBounds();
    }, [articlesPoints]);

    useEffect(() => {
        if (mapRef.current !== null) {
            mapRef.current.resize();
        }
    }, [mapWidth, mapHeight]);

    const { region: currentRegion = null } = useRegion(regionSlug, {
        enabled: regionSlug !== null,
        keepPreviousData: false,
        // placeholderData:
        //     regionSlug !== null
        //         ? {
        //               placeholder: true,
        //           }
        //         : null,
    });

    const { locationType: currentLocationType = null } = useLocationType(locationTypeSlug, {
        enabled: locationTypeSlug !== null,
        keepPreviousData: false,
    });

    const { location: loadedLocation = null } = useLocation(locationSlug, {
        enabled: locationSlug !== null && locationSlug.indexOf('toyota') === -1,
        keepPreviousData: false,
    });

    const { document: currentDocument = null } = useDocument(documentSlug, {
        enabled: documentSlug !== null,
        keepPreviousData: false,
    });

    const currentLocation =
        loadedLocation ||
        (locationSlug !== null
            ? (locations || []).find(({ slug = null }) => slug === locationSlug) ||
              toyotaDealers.find(
                  ({ name = null }) =>
                      name.toLowerCase().replaceAll(/[^a-z]/g, '-') === locationSlug,
              ) ||
              null
            : null);

    useEffect(() => {
        if (
            !sidebarOpened &&
            (currentRegion !== null || currentLocationType !== null || currentLocation !== null)
        ) {
            setSidebarOpened(true);
        }
    }, [setSidebarOpened, currentRegion, currentLocationType, currentLocation]);

    const filterLocations = useCallback(
        (slug) => {
            if (slug === null) {
                return articlesPoints;
            }

            return articlesPoints.filter((point) => {
                const {
                    properties: {
                        location: { types = [] },
                    },
                } = point;
                return types.some(({ slug: typeSlug }) => typeSlug === slug);
            });
        },
        [articlesPoints],
    );

    const filteredLocations = useMemo(
        () => filterLocations(currentLocationType !== null ? currentLocationType.slug : null),
        [currentLocationType, filterLocations],
    );

    // NAVIGATION
    const flyTo = useCallback(
        (latitude, longitude, newZoom) => {
            if (mapRef.current !== null) {
                mapRef.current.flyTo({
                    center: [latitude, longitude],
                    zoom: Math.min(newZoom, maxZoom),
                    essential: true,
                });
            }
        },
        [maxZoom],
    );

    const { flyToRegion, flyToLocation, flyToDocumentLocation } = useFlyTo(mapRef.current, {
        maxZoom,
        width: mapWidth,
        height: mapHeight,
    });

    useEffect(() => {
        if (currentRegion !== null) {
            flyToRegion(currentRegion);
        }

        if (currentLocation !== null) {
            flyToLocation(currentLocation);
        }

        if (currentDocument !== null) {
            flyToDocumentLocation(currentDocument);
        }
    }, [currentRegion, currentLocation, currentDocument, flyTo]);

    const onMove = useCallback(({ viewState }) => setViewport({ ...viewState }), [setViewport]);

    const onClickPoint = useCallback(
        (point) => {
            const { slug = null, name = null } = point || {};

            const finalSlug = slug !== null ? slug : name.toLowerCase().replaceAll(/[^a-z]/g, '-');

            setLocation(
                url('map.location', {
                    location: finalSlug || null,
                }),
            );
        },
        [currentLocation, setLocation, url],
    );

    return (
        <div
            className={classNames([
                styles.container,
                styles[brandHandle],
                className,
                contentClassName,
                {
                    [styles.sidebarOpened]: sidebarOpened,
                },
            ])}
        >
            <div className={classNames([styles.inner, 'hide-scrollbar'])}>
                <animated.div
                    ref={sidebarRef}
                    className={styles.sidebarContainer}
                    style={sidebarStyle}
                >
                    <MapSidebar
                        onClickToggle={toggleSidebar}
                        visible={sidebarOpened}
                        currentLocation={currentLocation}
                        currentRegion={currentRegion}
                        currentLocationType={currentLocationType}
                        currentDocument={currentDocument}
                        className={styles.sidebar}
                        innerClassName={classNames([styles.sidebarInner, 'hide-scrollbar'])}
                    />
                </animated.div>
                <animated.div
                    ref={mapSizeRef}
                    className={classNames([styles.mapContainer, 'drag-disabled'])}
                    style={contentStyle}
                >
                    <Map mapRef={mapRef} onMove={onMove} maxZoom={maxZoom} {...viewport}>
                        <Clusters
                            points={toyotaPoints}
                            bounds={bounds}
                            zoom={viewport.zoom}
                            theme="toyota"
                            markerIcons="toyota"
                            withoutClusterMarker
                            onClickPoint={onClickPoint}
                            markerClassName={classNames([
                                styles.marker,
                                {
                                    [styles.bigger]: viewport.zoom > 8,
                                },
                            ])}
                        />
                        {filteredLocations.length > 0 ? (
                            <Clusters
                                points={filteredLocations}
                                bounds={bounds}
                                zoom={viewport.zoom}
                                onClickPoint={onClickPoint}
                                markerClassName={classNames([
                                    styles.marker,
                                    {
                                        [styles.bigger]: viewport.zoom > 8,
                                    },
                                ])}
                            />
                        ) : null}
                    </Map>
                </animated.div>
            </div>
        </div>
    );
}

MapPage.propTypes = propTypes;
MapPage.defaultProps = defaultProps;

export default MapPage;
