import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import {
    createDocumentsMapList,
    createLocationTypesList,
    createRegionsList,
} from '../../lib/blocks';

import Blocks from '../blocks/Blocks';
import MenuSection from '../typography/MenuSection';

import styles from '../../styles/panels/map-main-panel.module.css';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function MapMainPanel({ className }) {
    const blocks = useMemo(
        () => [
            createLocationTypesList(
                10,
                { for: 'locations', order: 'count' },
                {
                    title: (
                        <MenuSection className={styles.sectionTitle}>
                            <FormattedMessage
                                defaultMessage="Catégories"
                                description="List title"
                            />
                        </MenuSection>
                    ),
                    itemsClassName: styles.items,
                },
            ),
            createRegionsList(
                { level: '2' },
                {
                    title: (
                        <MenuSection className={styles.sectionTitle}>
                            <FormattedMessage defaultMessage="Régions" description="List title" />
                        </MenuSection>
                    ),
                    itemsClassName: styles.items,
                },
            ),
            createDocumentsMapList(
                { for: 'locations' },
                {
                    title: (
                        <MenuSection className={styles.sectionTitle}>
                            <FormattedMessage
                                defaultMessage="Derniers contenus"
                                description="List title"
                            />
                        </MenuSection>
                    ),
                    itemsClassName: styles.items,
                },
            ),
        ],
        [],
    );

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <Blocks blocks={blocks} blockClassName={styles.list} />
        </div>
    );
}

MapMainPanel.propTypes = propTypes;
MapMainPanel.defaultProps = defaultProps;

export default MapMainPanel;
