/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useMemo, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useMap } from 'react-map-gl';

class Control {
    constructor(container) {
        this.container = container;
        this.map = null;
    }

    onAdd(map) {
        this.map = map;
        return this.container;
    }

    onRemove() {
        this.container.parentNode.removeChild(this.container);
        this.map = null;
    }
}

const propTypes = {
    children: PropTypes.node,
    position: PropTypes.string,
    className: PropTypes.string,
    tagName: PropTypes.string,
};

const defaultProps = {
    children: null,
    position: null,
    className: 'mapboxgl-ctrl',
    tagName: 'div',
};

function PortalControl({ children, position, className, tagName }) {
    const { current: map } = useMap();
    const container = useMemo(() => {
        if (map === null) {
            return null;
        }
        const el = document.createElement(tagName);
        el.className = className;
        return el;
    }, [map, tagName, className]);
    useEffect(() => {
        if (map === null) {
            return () => {};
        }
        const control = new Control(container);
        if (!map.hasControl(control)) {
            map.addControl(control, position);
        }
        return () => {
            if (map.hasControl(control)) {
                map.removeControl(control);
            }
        };
    }, [map, container, position]);
    return container ? (
        createPortal(children, container)
    ) : (
        <div className={className}>{children}</div>
    );
}

PortalControl.propTypes = propTypes;
PortalControl.defaultProps = defaultProps;

export default PortalControl;
