/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Typography from './Typography';

import styles from '../../styles/typography/menu-section.module.css';

const propTypes = {
    tag: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    tag: 'h3',
    className: null,
};

function MenuSection({ tag, className, ...props }) {
    return (
        <Typography
            tag={tag}
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            {...props}
        />
    );
}

MenuSection.propTypes = propTypes;
MenuSection.defaultProps = defaultProps;

export default MenuSection;
