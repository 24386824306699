import { useUrlGenerator } from '@folklore/routes';
import { animated, useTransition } from '@react-spring/web';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo, useRef } from 'react';
import { useLocation } from 'wouter';

import BasicButton from '../buttons/BasicButton';
import ArrowIcon from '../icons/ArrowIcon';
import CaretIcon from '../icons/CaretIcon';
import DocumentMapPanel from '../panels/DocumentMapPanel';
import LocationPanel from '../panels/LocationPanel';
import LocationTypePanel from '../panels/LocationTypePanel';
import MapMainPanel from '../panels/MapMainPanel';
import RegionPanel from '../panels/RegionPanel';

import styles from '../../styles/maps/map-sidebar.module.css';

const propTypes = {
    visible: PropTypes.bool,
    onClickToggle: PropTypes.func,
    currentRegion: PropTypes.shape({ id: PropTypes.string }),
    currentLocation: PropTypes.shape({ id: PropTypes.string }),
    currentLocationType: PropTypes.shape({ id: PropTypes.string }),
    currentDocument: PropTypes.shape({ id: PropTypes.string }),
    children: PropTypes.number,
    className: PropTypes.string,
    innerClassName: PropTypes.string,
};

const defaultProps = {
    visible: false,
    onClickToggle: null,
    currentRegion: null,
    currentLocation: null,
    currentLocationType: null,
    currentDocument: null,
    children: null,
    className: null,
    innerClassName: null,
};

function MapSidebar({
    visible,
    onClickToggle,
    currentRegion,
    currentLocation,
    currentLocationType,
    currentDocument,
    children,
    className,
    innerClassName,
}) {
    const url = useUrlGenerator();

    const [location] = useLocation();
    const lastLocationRef = useRef(null);

    const panel = useMemo(() => {
        let newPanel;

        if (currentRegion !== null) {
            newPanel = { id: 'region', item: currentRegion };
        } else if (currentLocation !== null) {
            newPanel = { id: 'location', item: currentLocation };
        } else if (currentLocationType !== null) {
            newPanel = {
                id: 'locationType',
                item: currentLocationType,
            };
        } else if (currentDocument !== null) {
            newPanel = {
                id: 'document',
                backUrl: lastLocationRef.current,
                item: currentDocument,
            };
        } else {
            newPanel = { id: 'main' };
        }
        return newPanel;
    }, [currentRegion, currentLocation, currentLocationType, currentDocument]);

    lastLocationRef.current = location;

    const transitions = useTransition([panel], {
        keys: ({ id, item: { id: itemId } = {} }) => `${id}-${itemId}`,
        from: {
            x: 100,
            opacity: 0,
        },
        enter: {
            x: 0,
            opacity: 1,
        },

        leave: {
            x: -100,
            opacity: 0,
        },
    });

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.visible]: visible,
                    [className]: className !== null,
                },
            ])}
        >
            <BasicButton onClick={onClickToggle} className={styles.toggle}>
                <CaretIcon className={styles.caret} />
            </BasicButton>
            <div className={classNames([styles.inner, innerClassName])}>
                {transitions((style, { id, item, backUrl = null }, { phase }) => (
                    <animated.div
                        className={classNames([
                            styles.panel,
                            {
                                [styles[`${id}Panel`]]: typeof styles[`${id}Panel`] !== 'undefined',
                                [styles.leave]: phase === 'leave',
                            },
                        ])}
                        style={style}
                    >
                        {id !== 'main' ? (
                            <BasicButton
                                href={
                                    backUrl !== null
                                        ? url('map', {
                                              slug: backUrl,
                                          })
                                        : url('map')
                                }
                                className={styles.backButton}
                            >
                                <ArrowIcon className={styles.arrow} />
                            </BasicButton>
                        ) : null}
                        {id === 'region' ? (
                            <RegionPanel
                                region={item}
                                className={classNames([styles.innerPanel, styles[id]])}
                            />
                        ) : null}
                        {id === 'location' ? (
                            <LocationPanel
                                location={item}
                                className={classNames([styles.innerPanel, styles[id]])}
                            />
                        ) : null}
                        {id === 'locationType' ? (
                            <LocationTypePanel
                                locationType={item}
                                className={classNames([styles.innerPanel, styles[id]])}
                            />
                        ) : null}
                        {id === 'document' ? (
                            <DocumentMapPanel
                                document={item}
                                className={classNames([styles.innerPanel, styles[id]])}
                            />
                        ) : null}
                        {id === 'main' ? (
                            <MapMainPanel className={classNames([styles.innerPanel, styles[id]])} />
                        ) : null}
                    </animated.div>
                ))}

                {children}
            </div>
        </div>
    );
}

MapSidebar.propTypes = propTypes;
MapSidebar.defaultProps = defaultProps;

export default MapSidebar;
