/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

function ExternalLink({ color, className }) {
    return (
        <svg
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <rect x="0.5" y="0.757812" width="8" height="12" stroke={color} />
            <path
                d="M15.3536 7.11137C15.5488 6.9161 15.5488 6.59952 15.3536 6.40426L12.1716 3.22228C11.9763 3.02702 11.6597 3.02702 11.4645 3.22228C11.2692 3.41754 11.2692 3.73412 11.4645 3.92939L14.2929 6.75781L11.4645 9.58624C11.2692 9.7815 11.2692 10.0981 11.4645 10.2933C11.6597 10.4886 11.9763 10.4886 12.1716 10.2933L15.3536 7.11137ZM5 7.25781H15V6.25781H5V7.25781Z"
                fill={color}
            />
        </svg>
    );
}

ExternalLink.propTypes = propTypes;
ExternalLink.defaultProps = defaultProps;

export default ExternalLink;
