import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { createDocumentsMapList } from '../../lib/blocks';
import { getComponentFromName } from '@folklore/utils';

import Blocks from '../blocks/Blocks';
import * as iconComponents from '../icons/maps/index';
import MenuSection from '../typography/MenuSection';
import SidebarTitle from '../typography/SidebarTitle';

import styles from '../../styles/panels/location-type-panel.module.css';

const propTypes = {
    locationType: PropTypes.shape({
        id: PropTypes.string,
        slug: PropTypes.string,
        label: PropTypes.string,
    }).isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function LocationTypePanel({ locationType, className }) {
    const {
        id: locationTypeId = null,
        label: title = null,
        icon = null,
        slug = null,
    } = locationType || {};

    const IconComponent = getComponentFromName(iconComponents, icon || slug, 'autres-sports');

    const blocks = useMemo(
        () => [
            createDocumentsMapList(
                { location_type: locationTypeId },
                {
                    title: (
                        <MenuSection className={styles.sectionTitle}>
                            <FormattedMessage
                                defaultMessage="Contenus associés"
                                description="List title"
                            />
                        </MenuSection>
                    ),
                    itemsClassName: styles.items,
                },
            ),
        ],
        [locationTypeId],
    );

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.header}>
                {title !== null ? (
                    <div className={styles.title}>
                        <IconComponent className={styles.icon} />
                        <SidebarTitle>{title}</SidebarTitle>
                    </div>
                ) : null}
            </div>
            <Blocks blocks={blocks} blockClassName={styles.list} />
        </div>
    );
}

LocationTypePanel.propTypes = propTypes;
LocationTypePanel.defaultProps = defaultProps;

export default LocationTypePanel;
