/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { getComponentFromName } from '@folklore/utils';

import * as iconComponents from '../icons/maps/index';
import BasicButton from './BasicButton';

import styles from '../../styles/buttons/map-control-button.module.css';

const propTypes = {
    icon: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.string,
    className: PropTypes.string,
    iconClassName: PropTypes.string,
};

const defaultProps = {
    icon: null,
    onClick: null,
    children: null,
    className: null,
    iconClassName: null,
};

function MapControlButton({ icon, onClick, children, className, iconClassName, ...props }) {
    const IconComponent = icon !== null ? getComponentFromName(iconComponents, icon) : null;
    return (
        <BasicButton
            onClick={onClick}
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            {...props}
        >
            {IconComponent !== null ? (
                <IconComponent
                    className={classNames([
                        styles.icon,
                        {
                            [iconClassName]: iconClassName !== null,
                        },
                    ])}
                />
            ) : null}
        </BasicButton>
    );
}

MapControlButton.propTypes = propTypes;
MapControlButton.defaultProps = defaultProps;

export default MapControlButton;
