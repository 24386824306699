/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Map as Mapbox } from 'react-map-gl';

import * as AppPropTypes from '../../lib/PropTypes';

import NavigationControl from './NavigationControl';

import styles from '../../styles/maps/map.module.css';
import 'mapbox-gl/dist/mapbox-gl.css';

const propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    mapRef: AppPropTypes.ref,
    theme: PropTypes.string,
    apiKey: PropTypes.string,

    children: PropTypes.node,
};

const defaultProps = {
    mapRef: null,
    theme: null,
    apiKey: 'pk.eyJ1IjoidXJiYW5pYSIsImEiOiJjbDgwZ3pncDYwNjFjM25udmtwMGo3aTh4In0.TgOXbEZwj67VXt6LKnG4xg',
    children: null,
};

function Map({ mapRef, apiKey, theme, children, ...props }) {
    const currentMapStyle =
        theme === 'winter'
            ? 'mapbox://styles/urbania/cl84lm7md001r14m3ks4srlyu'
            : 'mapbox://styles/urbania/cl80i6vhn002o15lugfinich6';

    const onZoomIn = useCallback(() => {
        if (mapRef.current !== null) {
            mapRef.current.zoomIn();
        }
    }, []);

    const onZoomOut = useCallback(() => {
        if (mapRef.current !== null) {
            mapRef.current.zoomOut();
        }
    }, []);

    return (
        <Mapbox ref={mapRef} mapboxAccessToken={apiKey} mapStyle={currentMapStyle} {...props}>
            <NavigationControl
                zoomIn={onZoomIn}
                zoomOut={onZoomOut}
                className={styles.navigation}
            />

            {children}
        </Mapbox>
    );
}

Map.propTypes = propTypes;
Map.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <Map {...props} buttonRef={ref} />);
