import geoViewport from '@mapbox/geo-viewport';
import mapboxgl from 'mapbox-gl';
import { useCallback } from 'react';

export default function useFlyTo(map, { maxZoom, width, height }) {
    const flyTo = useCallback(
        (latitude, longitude, newZoom) => {
            if (map !== null) {
                map.flyTo({
                    center: [latitude, longitude],
                    zoom: Math.min(newZoom, maxZoom),
                    essential: true,
                });
            }
        },
        [map, maxZoom],
    );

    const flyToRegion = useCallback(
        (region) => {
            const { bounds: regionBounds = null } = region || {};

            if (regionBounds === null) {
                return;
            }
            const {
                southwest_longitude: southwestLongitude,
                southwest_latitude: southwestLatitude,
                northeast_longitude: northeastLongitude,
                northeast_latitude: northeastLatitude,
            } = regionBounds || {};

            const finalBounds = [
                southwestLongitude,
                southwestLatitude,
                northeastLongitude,
                northeastLatitude,
            ];

            if (finalBounds !== null) {
                const {
                    center: [boundsLongitude, boundsLatitude],
                    zoom: boundsZoom,
                } = geoViewport.viewport(finalBounds, [width - 100, height - 100]);
                flyTo(boundsLongitude, boundsLatitude, boundsZoom - 0.5);
            }
        },
        [flyTo, width, height],
    );

    const flyToLocation = useCallback(
        (location) => {
            const { longitude: locationLongitude, latitude: locationLatitude } = location || {};

            if (locationLongitude === null || locationLatitude === null) {
                return;
            }
            flyTo(locationLongitude, locationLatitude, maxZoom - 3);
        },
        [flyTo, maxZoom],
    );

    const flyToDocumentLocation = useCallback(
        (document) => {
            if (document !== null) {
                const {
                    metadata: { locations: currentDocumentLocations = [] },
                } = document || {};

                const finalDocumentBounds = currentDocumentLocations.reduce(
                    (documentBounds, { longitude = null, latitude = null }) =>
                        longitude !== null && latitude !== null
                            ? documentBounds.extend([longitude, latitude])
                            : documentBounds,
                    new mapboxgl.LngLatBounds(),
                );

                const {
                    center: [centerLongitude, centerLatitude],
                    zoom: boundsZoom,
                } = geoViewport.viewport(
                    [
                        finalDocumentBounds.getWest(),
                        finalDocumentBounds.getSouth(),
                        finalDocumentBounds.getEast(),
                        finalDocumentBounds.getNorth(),
                    ],
                    [width - 100, height - 100],
                    undefined,
                    maxZoom,
                );
                if (centerLongitude !== null && centerLatitude !== null) {
                    flyTo(centerLongitude, centerLatitude, boundsZoom - 0.5);
                }
            }
        },
        [flyTo, maxZoom],
    );

    return {
        flyToRegion,
        flyToLocation,
        flyToDocumentLocation,
    };
}
