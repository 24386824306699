/* eslint-disable react/jsx-props-no-spreading */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'wouter';

import { useDocumentUrlGenerator } from '../../hooks/useUrlGenerator';
import useElementSize from '../../hooks/useElementSize';
import { createLocationsList } from '../../lib/blocks';

import Blocks from '../blocks/Blocks';
import PillButton from '../buttons/PillButton';
import ExternalLinkIcon from '../icons/ExternalLinkIcon';
import DocumentImage from '../partials/DocumentImage';
import Sponsor from '../partials/Sponsor';
import MenuSection from '../typography/MenuSection';
import SidebarTitle from '../typography/SidebarTitle';

import styles from '../../styles/panels/document-map-panel.module.css';

const propTypes = {
    document: PropTypes.shape({
        id: PropTypes.string,
        slug: PropTypes.string,
    }).isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function DocumentMapPanel({ document, className }) {
    const {
        title = null,
        image = null,
        type = null,
        slug = null,
        metadata = null,
    } = document || {};

    const { sponsors = null, locations = null } = metadata || {};

    const url = useDocumentUrlGenerator();

    const { ref: containerRef, width: containerWidth = 0 } = useElementSize();

    const blocks = useMemo(
        () => [
            createLocationsList(locations, null, {
                title: (
                    <MenuSection className={styles.sectionTitle}>
                        <FormattedMessage
                            defaultMessage="Lieux associés"
                            description="List title"
                        />
                    </MenuSection>
                ),
                itemsClassName: styles.items,
            }),
        ],
        [locations],
    );

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            ref={containerRef}
        >
            <DocumentImage
                type={type}
                media={image}
                width={containerWidth}
                height={300}
                responsive
                className={styles.image}
            />
            <div className={styles.header}>
                <Link
                    href={
                        url({
                            type,
                            slug,
                        }) || '#'
                    }
                    className={classNames([styles.title, styles.link])}
                >
                    <SidebarTitle>{title}</SidebarTitle>
                </Link>

                <PillButton
                    href={
                        url({
                            type,
                            slug,
                        }) || '#'
                    }
                    className={styles.button}
                >
                    <FormattedMessage defaultMessage="Consulter" description="Button label" />
                    <ExternalLinkIcon className={styles.icon} />
                </PillButton>

                {sponsors !== null
                    ? (sponsors || []).map((sponsor) => <Sponsor {...sponsor} />)
                    : null}
            </div>

            <Blocks blocks={blocks} blockClassName={styles.list} />
        </div>
    );
}

DocumentMapPanel.propTypes = propTypes;
DocumentMapPanel.defaultProps = defaultProps;

export default DocumentMapPanel;
