import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { createDocumentsMapList, createLocationTypesList } from '../../lib/blocks';

import Blocks from '../blocks/Blocks';
import MenuSection from '../typography/MenuSection';
import SidebarTitle from '../typography/SidebarTitle';

import styles from '../../styles/panels/region-panel.module.css';

const propTypes = {
    region: PropTypes.shape({
        id: PropTypes.string,
        handle: PropTypes.string,
        slug: PropTypes.string,
        label: PropTypes.string,
    }).isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function RegionPanel({ region, className }) {
    const { label: title = null, id: regionId = null } = region || {};

    const [activeLocationType, setActiveLocationType] = useState();

    const onClickLocationType = useCallback(
        (filter) => {
            if (filter === activeLocationType) {
                setActiveLocationType(null);
            } else {
                setActiveLocationType(filter);
            }
        },
        [activeLocationType, setActiveLocationType],
    );

    const blocks = useMemo(
        () => [
            createLocationTypesList(
                10,
                { region: regionId },
                {
                    title: (
                        <MenuSection className={styles.sectionTitle}>
                            <FormattedMessage
                                defaultMessage="Incontournables de la région"
                                description="List title"
                            />
                        </MenuSection>
                    ),
                    cardProps: ({ slug = null }) => ({
                        onClick: onClickLocationType,
                        active: activeLocationType !== null && slug === activeLocationType,
                    }),
                    itemsClassName: styles.items,
                },
            ),
            createDocumentsMapList(
                {
                    region: regionId,
                    location_type: activeLocationType,
                },
                {
                    title: (
                        <MenuSection className={styles.sectionTitle}>
                            <FormattedMessage
                                defaultMessage="Contenus associés"
                                description="List title"
                            />
                        </MenuSection>
                    ),
                    itemsClassName: styles.items,
                },
            ),
        ],
        [regionId, onClickLocationType, activeLocationType],
    );

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.header}>
                {title !== null ? (
                    <div className={styles.title}>
                        <SidebarTitle>{title}</SidebarTitle>
                    </div>
                ) : null}
            </div>
            <Blocks blocks={blocks} blockClassName={styles.list} />
        </div>
    );
}

RegionPanel.propTypes = propTypes;
RegionPanel.defaultProps = defaultProps;

export default RegionPanel;
